<template>
  <b-modal :title="$t('Addresses')" v-model="showModal">
    <p class="my-4 text-left">
      <b-list-group>
        <b-list-group-item
          href="#"
          @click="addressSelected(address)"
          class="flex-column align-items-start"
          v-for="address in addresses"
          :key="address.id"
          >
          <div>
            <h5 class="mb-1">{{ address.firstname }} {{ address.lastname }}</h5>
          </div>
          <p class="mb-1">
            {{ address.street.join(' ') }}
            {{ address.city }}, {{ address.region.region_code }} {{ address.postcode }} {{ address.country_id }}
          </p>
        </b-list-group-item>
      </b-list-group>
    </p>
    <b-row slot="modal-footer">
      <b-col cols="12">
        <b-button variant="secondary" @click="showModal = false">{{ $t('Cancel') }}</b-button>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState({
      addresses: state => state.user.current ? state.user.current.addresses : []
    }),
    showModal: {
      get () {
        return this.value
      },
      set (newVal) {
        this.$emit('input', newVal)
      }
    }
  },
  methods: {
    addressSelected (address) {
      this.$emit('addressSelected', address)
      this.showModal = false
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.list-group-item{
  &:hover{
    background-color: map-get($colors, primary);
    color: map-get($colors, primary-color);
  }
}
</style>
